/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
.link {
  background: transparent;
}

.link a {
  font-size: 13px;
  color: #707070;
  border-bottom: 1px solid #89C6CC;
}

.link a:hover {
  color: #878787;
}
